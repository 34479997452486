



































































































































































































































































































































































































import VueBase from '../../VueBase'
import { Component, Prop, Watch, Emit } from 'vue-property-decorator'
import { Form } from 'element-ui'
import HookTable from '@/views/project/components/hookTable.vue'
import DowmloadAgent from './components/downloadAgent.vue'

@Component({
  name: 'ProjectEdit',
  components: {
    HookTable,
    DowmloadAgent,
  },
})
export default class ProjectEdit extends VueBase {
  @Emit('closeDrawerEvent')
  closeDrawer(flag: boolean): void {}
  @Prop() newEdit: any = false
  pId: any = ''
  private changeMenu = '基础设置'
  private type = '1'
  private advanced = false
  private departmentList = []
  private projectList = []
  private submitForm: {
    name: string
    mode: string
    agentIdList: Array<number>
    scanId: number | undefined
    version_name: string
    description: string
    vul_validation: number
    base_url: string
    test_req_header_key: string
    test_req_header_value: string
    template_id: any
    log_level: any
    enable_log: any
  } = {
    name: '',
    mode: this.$t('views.projectEdit.mode1') as string,
    agentIdList: [],
    scanId: undefined,
    version_name: '',
    description: '',
    vul_validation: 0,
    base_url: '',
    test_req_header_key: '',
    test_req_header_value: '',
    template_id: '',
    log_level: '',
    enable_log: true,
  }
  private engineList: Array<{
    id: number
    short_name: string
    token: string
  }> = []
  private engineSelectedList: Array<{
    id: number
    token: string
  }> = []
  private strategyList: Array<{ id: number; name: string }> = []
  private strategyTypeList: any = []
  private defaultProps: any = {
    children: 'type_value',
    label: 'vul_name',
  }
  private rules = {
    name: [
      {
        validator: this.validatorName,
        required: true,
        trigger: 'blur',
      },
    ],
    scanId: [
      {
        required: true,
        message: '请选择扫描策略',
        trigger: 'change',
      },
    ],
    template_id: [
      {
        required: true,
        message: this.$t('views.projectEdit.templatePlaceholder'),
        trigger: 'change',
      },
    ],
  }

  private isRepeat = false
  validatorName(rule: any, value: any, callback: any) {
    if (value === '') {
      callback(new Error('请输入应用名称'))
    } else if (this.isRepeat) {
      callback(new Error('应用名称重复'))
    } else {
      callback()
    }
  }

  private scanNameRules = [
    {
      required: true,
      trigger: 'blur',
      message: '请输入模板名称',
    },
    {
      validator: this.validatorScanName,
      trigger: 'change',
    },
  ]
  validatorScanName(rule: any, value: any, callback: any) {
    // console.log('value', value)
    if (value?.length > 30) {
      callback(new Error('不超过30个字符'))
    } else {
      callback()
    }
  }

  private scanAddDialogOpen = false
  private scanForm: {
    ids: Array<number>
    name: string
    fid: number
  } = {
    ids: [],
    fid: 1,
    name: '',
  }
  private isSelectAll = false
  private Hopen(i: any) {
    i.open = !i.open
    let arr = i.children.map((item: any) => {
      return item.name
    })
    console.log(arr)
    if (arr.includes(this.changeMenu)) {
      return
    }
    // this.changeMenu = i.children[0].name
  }
  private async getListDepartment() {
    // 部门list
    const res = await this.services.deploy.getDepartment({})
    if (res.status === 201) {
      this.departmentList = res.data
      return
    }
    this.$message.error(res.msg)
  }
  private async getListProjecttemplat() {
    const res = await this.services.setting.listProjecttemplat({
      page: 1,
      page_size: 100,
    })
    if (res.status === 201) {
      this.projectList = res.data
      return
    }
    this.$message.error(res.msg)
  }

  async created() {
    
    await this.getEngineList()
    // await this.getListDepartment()
    await this.getListProjecttemplat()
    await this.strategyUserList()
    await this.getUsers()
    if (this.pId) {
      this.newEdit = true
      await this.projectDetail()
      await this.getAuthedUsers()
    }
    
    this.changeMenu = '基础设置'
  }

  // async created() {
  //   await this.getEngineList()
  //   // await this.getListDepartment()
  //   await this.getListProjecttemplat()
  //   await this.strategyUserList()
  //   await this.getUsers()
  //   // if (this.$route.params.pid) {
  //   //   await this.projectDetail()
  //   //   await this.getAuthedUsers()
  //   // }
  //   this.changeMenu = '基础设置'
  // }

  @Watch('pId')
  async watchPidChange(val: any) {
    if (val) {
      this.pId = val
      this.newEdit = true
      await this.projectDetail()
      await this.getAuthedUsers()
    }
  }

  private scanAddDialogShow() {
    this.scanAddDialogOpen = true
    this.scanForm = {
      ids: [],
      fid: 1,
      name: '',
    }
    this.strategyTypes()
  }

  private async projectDetail() {
    const { status, msg, data } = await this.services.project.projectDetail(
      // this.$route.params.pid
      this.pId
    )
    if (status !== 201) {
      this.$message.error(msg)
      return
    }

    if (!this.strategyList.some((item) => data.scan_id === item.id)) {
      this.strategyList.push({ id: data.scan_id, name: data.scan_name })
    }

    this.submitForm.name = data.name
    this.submitForm.mode = data.mode
    this.submitForm.agentIdList = data.agents.map((item: { id: any }) => {
      return item.id
    })
    this.submitForm.scanId = data.scan_id
    this.submitForm.version_name = data.versionData?.version_name
    this.submitForm.description = data.versionData?.description
    this.submitForm.vul_validation = data.vul_validation
    this.submitForm.base_url = data.base_url
    this.submitForm.test_req_header_key = data.test_req_header_key
    this.submitForm.test_req_header_value = data.test_req_header_value
    this.submitForm.template_id = data.template_id
    this.submitForm.enable_log = data.enable_log
    this.submitForm.log_level = data.log_level

    this.agentChange()
  }

  async getAuthedUsers() {
    const { data, status } = await this.services.project.getAuthedUsers(
      // this.$route.params.pid
      this.pId
    )
    if (status !== 201) return
    this.users = data.map((item: any) => item.id)
    this.options = this.unique([...data, ...this.options])
  }
  unique(arr: any) {
    var result = arr.reduce((unique: any, o: any) => {
      if (!unique.some((obj: any) => obj.id === o.id)) {
        unique.push(o)
      }
      return unique
    }, [])
    return result
  }

  private async getEngineList() {
    // const idParams = this.$route.params.pid || '0'
    const idParams = this.pId || '0'
    const { status, msg, data } = await this.services.project.getEngineList(
      idParams
    )
    if (status !== 201) {
      this.$message.error(msg)
      return
    }
    this.engineList = data
  }

  private async strategyUserList() {
    this.loadingStart()
    const { status, msg, data } = await this.services.setting.strategyUserList()
    this.loadingDone()
    if (status !== 201) {
      this.$message.error(msg)
      return
    }
    this.strategyList = data
  }

  private async strategyTypes() {
    this.loadingStart()
    const { status, msg, data } = await this.services.setting.strategyTypes()
    this.loadingDone()
    if (status !== 201) {
      this.$message.error(msg)
      return
    }
    this.strategyTypeList = data
      .map((item: any) => {
        item.strategy_id = item.level_name
        item.vul_name = item.level_name
        item.isCollapse = item.type_value?.length > 0
        item.type_value = item.type_value.map((ele: any) => {
          ele.isCollapse = false
          return ele
        })
        return item
      })
      .filter((item: any) => item.isCollapse)
    console.log('this.strategyTypeList', this.strategyTypeList)
  }

  private strategyUserAdd() {
    ;(this.$refs.scanForm as any).validateField(
      'name',
      async (errorMessage: any) => {
        if (errorMessage) return
        this.scanForm.ids = (
          (this.$refs.tree as any)?.getCheckedKeys() || []
        ).filter((item: any) => typeof item === 'number')
        if (this.scanForm.ids.length < 1) {
          this.$message.error('请选择漏洞类型')
          return
        }
        const params = {
          ids: this.scanForm.ids.join(','),
          name: this.scanForm.name,
        }
        this.loadingStart()
        const { status, msg, data } =
          await this.services.setting.strategyUserAdd(params)
        this.loadingDone()
        if (status !== 201) {
          this.$message.error('创建策略失败，请重试')
          return
        }
        this.$message.success('创建策略成功')
        this.scanAddDialogOpen = false
        this.strategyUserList()
        this.submitForm.scanId = data.id
      }
    )
  }

  private agentChange() {
    this.engineSelectedList = this.engineList.reduce(
      (
        list: Array<{ id: number; token: string }>,
        item: { id: number; token: string }
      ) => {
        if (this.submitForm.agentIdList.includes(item.id)) {
          list.push({ ...item })
        }
        return list
      },
      []
    )
  }

  private projectAdd() {
    ;(this.$refs.submitForm as Form).validate(async (valid: any) => {
      if (valid) {
        const params: {
          name: string
          mode: string | any
          agent_ids: string
          scan_id: number
          pid?: string
          version_name: string | undefined
          description: string | undefined
          vul_validation: number
          base_url: string
          test_req_header_key: string
          test_req_header_value: string
          template_id: any
          enable_log: any
          log_level: any
        } = {
          name: this.submitForm.name,
          mode: this.submitForm.mode,
          agent_ids: this.submitForm.agentIdList.join(','),
          scan_id: this.submitForm.scanId as number,
          version_name: this.submitForm.version_name
            ? this.submitForm.version_name
            : undefined,
          description: this.submitForm.description
            ? this.submitForm.description
            : undefined,
          vul_validation: this.submitForm.vul_validation,
          base_url: this.submitForm.base_url,
          test_req_header_key: this.submitForm.test_req_header_key,
          test_req_header_value: this.submitForm.test_req_header_value,
          template_id: this.submitForm.template_id,
          enable_log: this.submitForm.enable_log,
          log_level: this.submitForm.log_level,
        }
        let message = '创建'
        // if (this.$route.params.pid) {
        //   params.pid = this.$route.params.pid
        //   message = '修改'
        // }
        if (this.pId) {
          params.pid = this.pId
          message = '修改'
        }
        const { status, data, msg } = await this.services.project.projectAdd(
          params
        )
        if (status === 203) {
          this.isRepeat = !false
          ;(this.$refs.submitForm as Form).validateField('name')
          return
        }
        if (status !== 201) {
          this.$message.error(msg)
          return
        }
        const res = await this.services.project.editAuthedUsers(
          data.project_id,
          {
            related_user: this.users,
          }
        )
        if (res.status !== 201) {
          this.$message.error(`应用${message}失败，请重试`)
          return
        }
        this.$message.success(`应用${message}成功`)
        // await this.$router.go(-1)
        this.closeDrawer(false)
        window.location.reload()
      } else {
        console.log('error submit!!')
        return false
      }
    })
  }

  private async remoteUser(query: string) {
    this.getUsers(query)
  }
  private options: any = []
  private users: any = []
  private async getUsers(name?: string | undefined) {
    const res = await this.services.project.getUsers({
      page: 1,
      pageSize: 50,
      name: name,
    })
    if (res.status === 201) {
      this.options = res.data
    }
  }

  private showDownloadDialog: boolean = false
  async showDownload() {
    this.showDownloadDialog = true
  }
}
